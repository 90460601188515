import React from "react";
import { useTranslation } from 'react-i18next';
import { usePageName } from "react-page-name";
import "./ferienwohnung.scss";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const Kontakt = ({ handleClickFerienwohnungView }) => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language === "de" ? "" : i18n.language + "/";

    usePageName(t("site.ferienwohnung") + " | " + t("site.pageName"));

    return (
        <div className="ferienwohnung">
            <div className="topBar">
                <CloseOutlinedIcon onClick={handleClickFerienwohnungView} />
            </div>
            <iframe
                allowFullScreen=""
                frameBorder="0"
                loading="lazy"
                src={`https://ilsire.com/proxy.php?lang=${lang}`}
                title="Vacation Bookings"
                width="100%"
                height="3400px"
            />
        </div>
    );
};

export default Kontakt;
