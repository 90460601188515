import React from "react";
import { useTranslation } from 'react-i18next';
import { usePageName } from "react-page-name";
import "./notfound.scss"

const NotFound = () => {

    const { t } = useTranslation();

    usePageName(t("site.pageName"));

    return (
        <div className="notfound">
            <div className="header">
                <div className="container">
                    <h1>ERROR 404</h1>
                </div>
            </div>
            <div className="container">
                <div className="content">
                    <h2>{t("notFound.H2")}</h2>
                    <h4>{t("notFound.H4")}</h4>
                </div>
            </div>
        </div>
    )
};

export default NotFound