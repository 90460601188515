import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePageName } from "react-page-name";
import ical from 'ical.js';
import moment from "moment";
import "moment/locale/de";  // import German locale
import "moment/locale/it";  // import Italian locale
import "./anfragen.scss";
import axios from "axios";

const Anfragen = () => {
    const { t, i18n } = useTranslation();
    const [checkIn, setCheckIn] = useState(null);
    const [checkOut, setCheckOut] = useState(null);
    const [summeDays, setSummeDays] = useState("");
    const [disabledDates, setDisabledDates] = useState([]);
    const [mailSend, setMailSend] = useState(false);
    const [formData, setFormData] = useState({
        personen: '2',
        vorname: '',
        nachname: '',
        email: '',
        tel: '',
        adresse: '',
        land: '',
        plz: '',
        ort: ''
    });
    const [formErrors, setFormErrors] = useState({});

    const currentDate = new Date();
    const [currentMonthIndex, setCurrentMonthIndex] = useState(currentDate.getMonth());
    const [currentYear, setCurrentYear] = useState(currentDate.getFullYear());

    usePageName(t("site.anfragen") + " | " + t("site.pageName"));

    useEffect(() => {
        const fetchIcalData = async () => {
            try {
                const response = await fetch('https://www.airbnb.de/calendar/ical/1146431451905078219.ics?s=55b1a8e7aef07b5893c5b19878bbeddf');
                const icalText = await response.text();
                const parsedData = ical.parseICS(icalText);
                const dates = Object.values(parsedData).map(event => new Date(event.start).toLocaleDateString('de-DE'));
                setDisabledDates(dates);
            } catch (error) {
                console.error("Failed to fetch iCal data", error);
            }
        };
        fetchIcalData();
    }, []);

    useEffect(() => {
        moment.locale(i18n.language);  // Set Moment.js locale based on i18next language
    }, [i18n.language]);

    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split('.').map(Number);
        return new Date(year, month - 1, day); // Monate sind 0-basiert
    };

    const handleDateSelection = (date) => {
        if (!checkIn) {
            setCheckIn(date);
        } else if (!checkOut) {
            setCheckOut(date);
        } else {
            setCheckIn(date);
            setCheckOut("");
        }
    };

    useEffect(() => {
        if (checkIn && checkOut) {
            const days = moment(checkOut, 'DD.MM.YYYY').diff(moment(checkIn, 'DD.MM.YYYY'), 'days');
            setSummeDays(`${days} ${t("anfragen.nächte")}`);
        } else {
            setSummeDays("");
        }
    }, [checkIn, checkOut, t]);

    const renderCalendarDays = (month, year) => {
        const date = new Date(year, month, 1);
        const firstDayIndex = (date.getDay() + 6) % 7;
        const lastDate = new Date(year, month + 1, 0).getDate();
        const today = new Date();

        const days = [];
        let currentDay = 1;

        for (let week = 0; week < 6; week++) {
            const weekDays = [];
            for (let day = 0; day < 7; day++) {
                if ((week === 0 && day < firstDayIndex) || currentDay > lastDate) {
                    weekDays.push(<td key={`${week}-${day}`} className="empty"></td>);
                } else {
                    const checkInDate = checkIn ? parseDate(checkIn) : null;
                    const checkOutDate = checkOut ? parseDate(checkOut) : null;

                    const currentDayValue = currentDay;
                    const currentDate = new Date(year, month, currentDay);
                    const isDisabled = disabledDates.includes(currentDate.toLocaleDateString('de-DE')) || currentDate < today || (checkInDate && currentDate <= checkInDate - 1) || (checkInDate && checkInDate <= checkInDate + 2);

                    const isSelected = currentDate.toLocaleDateString('de-DE') === checkIn || currentDate.toLocaleDateString('de-DE') === checkOut;
                    const isBetweenSelectedDates = checkInDate && checkOutDate && currentDate > checkInDate && currentDate < checkOutDate;

                    weekDays.push(
                        <td
                            key={`${week}-${day}`}
                            className={`${isDisabled ? "disabled" : ""} ${isSelected || isBetweenSelectedDates ? "selected" : ""}`}
                            role="button"
                            aria-disabled={isDisabled}
                            aria-label={`${currentDay}, ${new Date(year, month, currentDay).toLocaleDateString(i18n.language, { weekday: 'long' })}`}
                            onClick={() => !isDisabled && handleDateSelection(`${currentDayValue}.${month + 1}.${year}`)}
                        >
                            {currentDay}
                        </td>
                    );
                    currentDay++;
                }
            }
            days.push(<tr key={week}>{weekDays}</tr>);
        }

        return days;
    };

    const months = moment.months();  // Use moment's localized month names

    const handlePrevMonth = () => {
        const prevMonthDate = new Date(currentYear, currentMonthIndex);
        const currentDate = new Date();

        if (prevMonthDate >= currentDate) {
            setCurrentMonthIndex(prevIndex => (prevIndex === 0 ? 11 : prevIndex - 1));
            if (currentMonthIndex === 0) setCurrentYear(prevYear => prevYear - 1);
        }
    };

    const handleNextMonth = () => {
        setCurrentMonthIndex(prevIndex => (prevIndex + 1) % 12);
        if (currentMonthIndex === 11) setCurrentYear(prevYear => prevYear + 1);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateForm = () => {
        let errors = {};
        if (!formData.vorname) errors.vorname = (t('anfragen.vorname') + " " + t('anfragen.istErforderlich'));
        if (!formData.nachname) errors.nachname = (t('anfragen.nachname') + " " + t('anfragen.istErforderlich'));
        if (!formData.email) errors.email = (t('anfragen.email') + " " + t('anfragen.istErforderlich'));
        if (!formData.adresse) errors.adresse = (t('anfragen.adresse') + " " + t('anfragen.istErforderlich'));
        if (!formData.land) errors.land = (t('anfragen.land') + " " + t('anfragen.istErforderlich'));
        if (!formData.plz) errors.plz = (t('anfragen.plz') + " " + t('anfragen.istErforderlich'));
        if (!formData.ort) errors.ort = (t('anfragen.ort') + " " + t('anfragen.istErforderlich'));
        if (!checkIn) errors.checkIn = (t('anfragen.checkIn') + " " + t('anfragen.istErforderlich'));
        if (!checkOut) errors.checkOut = (t('anfragen.chekIn') + " " + t('anfragen.istErforderlich'));
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const sendEmail = async (event) => {
        event.preventDefault();

        if (!validateForm()) return;

        try {
            const response = await axios.post('https://ilsire.com/sendMail.php', {
                name: formData.vorname + " " + formData.nachname,
                email: formData.email,
                tel: formData.tel,
                personen: formData.personen,
                tage: summeDays,
                checkIn: moment(parseDate(checkIn)).format('dd, D. MMM YYYY'),
                checkOut: moment(parseDate(checkOut)).format('dd, D. MMM YYYY'),
                lang: i18n.language
            });

            console.log(response.data);
            setMailSend(true);

        } catch (err) {
            console.error('Error sending email:', err);
        }
    };

    return (
        <div className="anfragen">
            <div className="header">
                <div className="container">
                    <h1>{t("site.anfragen")}</h1>
                </div>
            </div>
            <div className="calendar">
                <div className="container">
                    <div className="summary">
                        <div className="left">
                            <h2>{checkIn ? moment(parseDate(checkIn)).format('dd, D. MMM YYYY') + " - " + (checkOut ? moment(parseDate(checkOut)).format('dd, D. MMM YYYY') : "") : t("anfragen.reisedatenWählen")}</h2>
                            <p>{summeDays || t("anfragen.mindestaufenthalt")}</p>

                        </div>
                    </div>
                    <div className="monthList">
                        <div className="controls">
                            <button onClick={handlePrevMonth}>{"<"}</button>
                            <button onClick={handleNextMonth}>{">"}</button>
                        </div>
                        <div className="scrollList">
                            {[0, 1].map(offset => {
                                const monthIndex = (currentMonthIndex + offset) % 12;
                                const yearOffset = currentMonthIndex + offset >= 12 ? 1 : 0;
                                return (
                                    <div className="month" key={monthIndex}>
                                        <span className="heading">{months[monthIndex]} {currentYear + yearOffset}</span>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>{t("Mo")}</th>
                                                    <th>{t("Di")}</th>
                                                    <th>{t("Mi")}</th>
                                                    <th>{t("Do")}</th>
                                                    <th>{t("Fr")}</th>
                                                    <th>{t("Sa")}</th>
                                                    <th>{t("So")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {renderCalendarDays(monthIndex, currentYear + yearOffset)}
                                            </tbody>
                                        </table>
                                    </div>
                                );
                            })}
                        </div>
                        {formErrors.checkIn && <span className="error">{formErrors.checkIn}</span>}
                    </div>
                </div>
            </div>
            <form className="form" method="post" onSubmit={sendEmail}>
                <div className="container">
                    <div className="form-group">
                        <label htmlFor="personen">{t("anfragen.personen")}<span>*</span></label>
                        <input
                            type="number"
                            id="personen"
                            name="personen"
                            value={formData.personen}
                            onChange={handleChange}
                            min="1"
                        />
                    </div>
                    <div className="row">
                        <div className="form-group">
                            <label htmlFor="vorname">{t("anfragen.vorname")}<span>*</span></label>
                            <input
                                type="text"
                                id="vorname"
                                name="vorname"
                                value={formData.vorname}
                                onChange={handleChange}
                            />
                            {formErrors.vorname && <span className="error">{formErrors.vorname}</span>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="nachname">{t("anfragen.nachname")}<span>*</span></label>
                            <input
                                type="text"
                                id="nachname"
                                name="nachname"
                                value={formData.nachname}
                                onChange={handleChange}
                            />
                            {formErrors.nachname && <span className="error">{formErrors.nachname}</span>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group">
                            <label htmlFor="email">{t("anfragen.email")}<span>*</span></label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                            {formErrors.email && <span className="error">{formErrors.email}</span>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="tel">{t("anfragen.tel")}</label>
                            <input
                                type="tel"
                                id="tel"
                                name="tel"
                                value={formData.tel}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group">
                            <label htmlFor="adresse">{t("anfragen.adresse")}<span>*</span></label>
                            <input
                                type="text"
                                id="adresse"
                                name="adresse"
                                value={formData.adresse}
                                onChange={handleChange}
                            />
                            {formErrors.adresse && <span className="error">{formErrors.adresse}</span>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="land">{t("anfragen.land")}<span>*</span></label>
                            <input
                                type="text"
                                id="land"
                                name="land"
                                value={formData.land}
                                onChange={handleChange}
                            />
                            {formErrors.land && <span className="error">{formErrors.land}</span>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group">
                            <label htmlFor="plz">{t("anfragen.plz")}<span>*</span></label>
                            <input
                                type="text"
                                id="plz"
                                name="plz"
                                value={formData.plz}
                                onChange={handleChange}
                            />
                            {formErrors.plz && <span className="error">{formErrors.plz}</span>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="ort">{t("anfragen.ort")}<span>*</span></label>
                            <input
                                type="text"
                                id="ort"
                                name="ort"
                                value={formData.ort}
                                onChange={handleChange}
                            />
                            {formErrors.ort && <span className="error">{formErrors.ort}</span>}
                        </div>
                    </div>
                    <button>{t("site.anfragen")}</button>
                    {mailSend &&
                        <div className="gesendet">
                            <span>{t("anfragen.success")}</span>
                        </div>
                    }
                </div>
            </form>
        </div >
    );
};

export default Anfragen;