import React, { useEffect, useState } from "react";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import axios from "axios";
import Slider from 'react-slick';
import "../gallery/gallery.scss"


const Gallery = () => {

    const [gallery, setGallery] = useState([]);
    const [isLoading, setLoading] = useState(true);

    const CustomPrevArrow = (props) => {
        const { onClick } = props;
        return (
            <div className="arrow prev" onClick={onClick}>
                <ArrowBackOutlinedIcon />
            </div>
        );
    };

    const CustomNextArrow = (props) => {
        const { onClick } = props;
        return (
            <div className="arrow next" onClick={onClick}>
                <ArrowForwardOutlinedIcon />
            </div>
        );
    };

    const gallerySettings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 5000,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />
    };

    useEffect(() => {

        const fetchData = async () => {
            try {

                // Fetch für Header
                const galleryRes = await axios.get(`https://ilsire.protec-bz.it/admin/wp-json/wp/v2/gallery`);
                if (galleryRes.data && galleryRes.data.length > 0) {
                    setGallery(galleryRes.data);
                }

                setLoading(false);
            } catch (err) {
                console.error(err);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="gallery">
            {isLoading ? <div className="loading" /> : <Slider {...gallerySettings}>
                {gallery.map((item, index) => (
                    <div key={index}>
                        {item.acf.image.endsWith(".mp4") ? (
                            <video muted loop autoPlay src={item.acf.image} />
                        ) : (
                            <img src={item.acf.image} alt="Hintergrundbild" />
                        )}
                    </div>
                ))}
            </Slider>}
        </div>
    )
};

export default Gallery