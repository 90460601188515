import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import "../footer/footer.scss"


const Footer = () => {

    const { t, i18n } = useTranslation();

    return (
        <footer className="footer">
            <div className="container">
                <div className="top">
                    <div className="logo">
                        <img src="/assets/logo.svg" alt="Logo" />
                        <h2>il Sire</h2>
                        <span>{t("site.ferienwohnung")}</span>
                    </div>
                    <div className="contact">
                        <Link to={"https://www.google.com/maps/place/il+Sire+-+Ferienhaus/@45.4578386,10.5628535,17z/data=!3m1!4b1!4m6!3m5!1s0x478195809d20aaf9:0xfadcc602dcc160ef!8m2!3d45.4578349!4d10.5654284!16s%2Fg%2F11j2wlm7hb?entry=ttu"} target="_blank"><LocationOnOutlinedIcon /> {t("impressum.adresse")}</Link>
                        <Link to={"tel:+39 379 154 0120"}><LocalPhoneOutlinedIcon /> +39 379 154 0120</Link>
                        <Link to={"mailto:info@ilsire.com"}><EmailOutlinedIcon /> info@ilsire.com</Link>
                    </div>
                </div>
                <div className="bottom">
                    <div className="left">
                        <span>© {new Date().getFullYear()} {t("site.pageName")}</span>
                    </div>
                    <div className="right">
                        <NavLink to={`/${i18n.language}/impressum`}>{t("site.impressum")}</NavLink>
                        <span>|</span>
                        <NavLink to={`/${i18n.language}/datenschutz`}>{t("site.datenschutz")}</NavLink>
                        <span>|</span>
                        <NavLink to={`/${i18n.language}/kontakt`}>{t("site.kontakt")}</NavLink>
                    </div>
                </div>
            </div>
        </footer>
    )
};

export default Footer