import React from 'react'
import { useState, useEffect } from 'react'
import { usePageName } from "react-page-name";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import "./entdecken.scss"
import moment from 'moment';
import 'moment/locale/de'
moment.locale('de')

const Entdecken = () => {
    const { t, i18n } = useTranslation();

    usePageName(t("site.entdecken") + " | " + t("site.pageName"));

    const [entdecken, setEntdecken] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const lang = i18n.language === "it" ? "it/" : "";

    useEffect(() => {
        const fetchAllRef = async () => {
            try {
                // Fetch für Aussstattung
                const entdeckenRes = await axios.get(`https://ilsire.com/admin/wp-json/wp/v2/entdecken`);
                if (entdeckenRes.data) {
                    setEntdecken(entdeckenRes.data);
                }

                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        }
        fetchAllRef()
    }, [lang])

    return (
        <div className='entdecken'>
            <div className='header'>
                <div className="container">
                    <h1>{t("site.entdecken")}</h1>
                </div>
            </div>
            <div className="beschreibung">
                <div className="container">
                    <h3>{t("entdecken.headingHeader")}</h3>
                    <p>{t("entdecken.textHeader")}</p>
                </div>
            </div>
            <div className="container">
                <div className="grid">
                    {isLoading ? <div className='loading' /> : entdecken.map(item => (
                        <div key={item.id} className="item">
                            <img
                                className="image"
                                src={item.acf.image}
                                alt={item.title.rendered}
                            />
                            <div className="content">
                                <h2>{item.title.rendered}</h2>
                                <div className="wp-content" dangerouslySetInnerHTML={{ __html: item.content.rendered }} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Entdecken
