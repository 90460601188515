import React, { useEffect, useState } from "react";
import { usePageName } from "react-page-name";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import axios from "axios";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "./home.scss"
import Gallery from "../../components/gallery/gallery";
import Ferienwohnung from "../../components/ferienwohnung/ferienwohnung";

const Home = () => {
    const { t, i18n } = useTranslation();

    usePageName(t("site.pageName"));

    const [header, setHeader] = useState([]);
    const [ausstattung, setAusstattung] = useState([]);
    const [ferienwohnungView, setFerienwohnungView] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const lang = i18n.language === "it" ? "it/" : "";

    const CustomPrevArrow = (props) => {
        const { onClick } = props;
        return (
            <div className="arrow prev" onClick={onClick}>
                <ArrowBackOutlinedIcon />
            </div>
        );
    };

    const CustomNextArrow = (props) => {
        const { onClick } = props;
        return (
            <div className="arrow next" onClick={onClick}>
                <ArrowForwardOutlinedIcon />
            </div>
        );
    };

    const handleClickFerienwohnungView = event => {
        // 👇️ toggle isActive state on click
        setFerienwohnungView(current => !current);
    };

    const sliderSettings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />
    };

    useEffect(() => {

        const fetchData = async () => {
            try {
                // Fetch für Header
                const headerRes = await axios.get(`https://ilsire.com/admin/wp-json/wp/v2/header`);
                if (headerRes.data && headerRes.data.length > 0) {
                    setHeader(headerRes.data);
                }

                // Fetch für Aussstattung
                const ausstattungRes = await axios.get(`https://ilsire.com/admin/wp-json/wp/v2/ausstattung`);
                if (ausstattungRes.data) {
                    setAusstattung(ausstattungRes.data);
                }

                setLoading(false);
            } catch (err) {
                console.error(err);
            }
        };

        fetchData();
    }, [lang]);

    useEffect(() => {
        document.body.style.overflow = ferienwohnungView ? 'hidden' : 'auto';
    }, [ferienwohnungView]);

    const getBezeichnung = (item) => {
        switch (i18n.language) {
            case 'de':
                return item.acf.bezeichnung_de;
            case 'it':
                return item.acf.bezeichnung_it;
            case 'en':
                return item.acf.bezeichnung_en;
            default:
                return item.acf.bezeichnung_de;
        }
    };

    return (
        <div className="home">
            <div className="header-start">
                <div className="container">
                    <h1>{t("home.headerText")}</h1>
                </div>
                <div className="slideshow">
                    {isLoading ? (
                        <div className="laden"></div>
                    ) : (
                        <>
                            <Slider {...sliderSettings}>
                                {header.map((item, index) => (
                                    <div key={index}>
                                        {item.acf.hintergrund.endsWith(".mp4") ? (
                                            <video muted loop autoPlay src={item.acf.hintergrund} />
                                        ) : (
                                            <img src={item.acf.hintergrund} alt="Hintergrundbild" />
                                        )}
                                    </div>
                                ))}
                            </Slider>
                        </>
                    )}
                </div>
            </div>
            <div className="about">
                <div className="container">
                    <h3>{t("home.headerText")}</h3>
                    <p>{t("home.textStart")}</p>
                    <Link to={`/${i18n.language}/anfragen`}>{t("anfragen.button")}</Link>
                </div>
            </div>
            <div className="room">
                <div className={ferienwohnungView ? "modal active" : "modal"}>
                    <Ferienwohnung />
                </div>
                <div className="container">
                    <div className="house">
                        <h3>{t("room.heading")}</h3>
                        <h1>{t("site.pageName")}</h1>
                        <Gallery />
                        <div className="services">
                            <h2>{t("room.ausstattung")}</h2>
                            <div className="list">
                                {isLoading ? <div className='loading' /> : ausstattung.map(item => (
                                    <div key={item.id} className="item">
                                        <div className="wp-content" dangerouslySetInnerHTML={{ __html: item.content.rendered }} />
                                        <span>{getBezeichnung(item)}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="info">
                            <Link onClick={handleClickFerienwohnungView}>{t("btn.weitereInfos")}</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="vacation">
                <div className="container">
                    <h3>{t("home.genießenSie")}</h3>
                    <h1>{t("home.entdeckenH1")}</h1>
                    <div className="content">
                        <div className="left">
                            <p>{t("entdecken.text")}</p>
                            <Link to={`/${i18n.language}/entdecken`}>{t("entdecken.link")}</Link>
                        </div>
                        <div className="right">
                            <div className="item">
                                <img src="https://ilsire.protec-bz.it/admin/wp-content/uploads/2024/06/casa-vacanza-il-sire-cultura.webp" alt="" />
                                <img src="https://ilsire.protec-bz.it/admin/wp-content/uploads/2024/06/pexels-photo-14965990.webp" alt="" />
                            </div>
                            <div className="item">
                                <img src="https://ilsire.protec-bz.it/admin/wp-content/uploads/2024/06/Casa-vacanza-il-sire-spa-e1666882434508.webp" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Home