import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePageName } from "react-page-name";
import "./kontakt.scss";

const Kontakt = () => {
    const { t } = useTranslation();

    usePageName(t("site.kontakt") + " | " + t("site.pageName"));

    return (
        <div className="kontakt">
            <div className="header">
                <div className="container">
                    <h1>{t("site.kontakt")}</h1>
                </div>
            </div>
            <div className="container">
                <h2>{t("site.pageName")}</h2>
                <div className="gastgeber">
                    <img src="https://ilsire.com/admin/wp-content/uploads/2024/06/bd1aaf34-8e3d-4e71-857f-97004db4936c.webp" alt="" />
                    <div className="right">
                        <h3>{t("kontakt.gastgeber")}: Kathrin</h3>
                        <span>{t("kontakt.kontaktieren")}</span>
                        <div className="links">
                            <Link to={"tel:+393791540120"}><button>+39 379 154 0120</button></Link>
                            <Link to={"mailto:info@ilsire.com"}><button>info@ilsire.com</button></Link>
                        </div>
                    </div>
                </div>
                <iframe
                    allowFullScreen=""
                    frameBorder="0"
                    loading="lazy"
                    src="https://www.google.com/maps/embed/v1/search?key=AIzaSyDcHruo0hR5wUslR32AyekdXtG1PmpMcrk&amp;q=il+Sire+-+Ferienhaus&amp;zoom=16"
                    title="Karte"
                    width="100%"
                    height="800px"
                />
            </div>
        </div>
    );
};

export default Kontakt;
